

window.addEventListener('load', (event) => {
    //console.log('v1.0');
    
});

document.addEventListener('readystatechange', (event) => {
    // console.log(`readystate: ${document.readyState}`);
    
});

document.addEventListener('DOMContentLoaded', (event) => {
    // console.log('DOMContentLoaded');
});

 


document.onreadystatechange = () => {
    if (document.readyState === 'complete') {
        let stateCheck = setInterval(() => {
            if (document.getElementById('search_date_range_calendar') != null) {
                clearInterval(stateCheck);
                // document ready
                var picker = new Litepicker({ 
                    element: document.getElementById('search_date_range_calendar'),
                    plugins: ['mobilefriendly'],
                    singleMode: false,
                    format: 'MM/DD/YYYY',
                    tooltipText: {
                        one: 'day',
                        other: 'days'
                    },
                    tooltipNumber: (totalDays) => {
                        return totalDays - 1;
                    },
                    setup: (picker) => {
                        picker.on('selected', (date1, date2) => {
                            var pf = $('#daterange_picker');
                            pf.submit();
                        });
                    }
                });
            }
        }, 100);
    }
};




